.static-view {
	margin-top: var(--page-margin-top);

	.inner-banner {
		&.RundLaboe_banner {
			background-image: url(RESOURCE/img/banner_rund.jpg);
		}

		&.marine_banner {
			background-image: url(RESOURCE/img/banner_Marine.jpg);
		}

		&.veranstaltungen_banner {
			background-image: url(RESOURCE/img/banner_veranstaltungen.jpg);
		}

		&.naturerlebnisraum_banner {
			background-image: url(RESOURCE/img/banner_naturerlebnisraum.jpg);
		}

		&.unserer_banner {
			background-image: url(RESOURCE/img/banner_unserer.jpg);
		}

		&.vermieter {
			background-image: url(RESOURCE/img/exclusiver_banner.jpg);
		}

		&.anfahrt {
			background-image: url(RESOURCE/img/exclusiver_banner.jpg);
		}

		&.kontact {
			background-image: url(RESOURCE/img/exclusiver_banner.jpg);
		}

		&.about {
			background-image: url(RESOURCE/img/banner_aboutus.jpg);
		}
	}

	iframe {
		width: 100%;
		height: 100%;
		border: 0;
	}

	p {
		a {
			font-weight: 600;
			color: #ff7000;
		}
	}


	img {
		width: 100%;
	}

	.anfahrt-col {
		a {
			display: inline-block;
		}

		img {
			max-height: 200px;
			width: auto;
			height: 100%;
		}
	}

	ul {
		list-style: disc;
		padding-left: 15px;
	}
}