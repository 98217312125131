.faq-v2 {
	margin-top: var(--page-margin-top);

	#accordion {

		.card {
			background-color: transparent;
			border: 1px solid var(--color-grey-normal);

			.card-header {
				background-color: var(--faq-title-bg-color);
				border-bottom: none;
				padding: 0;
			}

		}

		.btn-link {
			font-weight: normal;
			color: var(--faq-title-font-color);
			text-decoration: none;
			width: 100%;
			text-align: left;
			font-size: var(--font-size-main);
			padding: .5rem 1rem;
			position: relative;

			&[aria-expanded="true"] {
				&:after {
					transform: rotate(180deg);
				}

			}

			&:after {
				content: "\f107";
				position: absolute;
				right: 15px;
				top: 10px;
				transition: all 0.2s ease;
				transform: rotate(0deg);
				font-family: "Font Awesome 5 Free";
				font-weight: 700;
				display: inline-block;
				font-style: normal;
				font-variant: normal;
				text-rendering: auto;
				line-height: 1;



			}

			&:hover {
				color: var(--faq-title-font-color);
				text-decoration: none;
			}
		}
	}

	.card {
		background-color: transparent;
		border: 1px solid var(--color-grey-normal);

		.card-header {
			background-color: var(--faq-title-bg-color);
			border-bottom: none;
			padding: 0px 5px;
		}

	}

	.btn-link {
		font-weight: bold;
		color: var(--faq-title-font-color);
		text-decoration: none;
		width: 100%;
		text-align: left;
		font-size: var(--font-size-main);

		&:hover {
			color: var(--faq-title-font-color);
			text-decoration: none;
		}
	}

}