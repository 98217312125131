.fuer-vermieter-v1 {

	.introSection {
		.container {
			.text-center {
				/* border-bottom: 1px solid var(--font-color-main);
				padding: 20px; */
			}
		}
	}

	.dienstleistungen {

		.highlight-box {
			border: 2px solid var(--font-color-main);
			/* Border color similar to yellow in screenshot */
			border-radius: 8px;
			padding: 20px;
		}

		.icon-container {
			text-align: center;
			padding: 20px;
		}

		.icon {
			img {
				width: 70%;
				transition: all 0.5s ease;
				cursor: pointer;

				&:hover {
					transform: scale(1.1);
				}
			}
		}

		.title {
			font-weight: bold;
			font-size: 1.16rem;
			margin-top: 15px;
		}

		@media (max-width: 767px) {
			.highlight-box {
				margin: 10px;
				padding: 10px;
			}

			.icon-container {
				padding: 10px 0;
			}

			.icon {
				img {
					width: 40%;
				}
			}

			.title {
				font-size: 1rem;
			}

			.description {
				font-size: 0.9rem;
			}

			.image-bl {
				img {
					width: 32.5%;
				}
			}

		}

		.image-bl {
			img {
				width: 37.8%;
				transition: all 0.5s ease;
				cursor: pointer;

				&:hover {
					transform: scale(1.1);
				}
			}
		}
	}

	.vorteile {
		.row {
			justify-content: space-between;
		}
	}

	.familienGeschichte {
		.image-bl {
			img {
				width: 70%;
			}
		}
	}

	.faq-v2 {
		margin-top: 0.5rem;
	}


}